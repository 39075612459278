<template>
	<div class="page" style="background:#EEEEEE;background: #ebf1fa;">
		<div class="inpage">
			<div class="addr" v-for="item in MyList">
				<div @click="ChooseItem(item.id)" class="font14">
					<div class="addrtitle">
						<span class="addruser">姓名:{{item.username}}</span>
						<span class="addrphone"></span>
					</div>
					<div class="adddetail" style="padding-top:8px;;">手机:{{item.phone}}</div>
					<div class="addremail" style="height: auto;;">地址:
						<span class="addrzone" style="padding-left:0px;padding-right:0px">{{item.province}}</span>
						<span class="addrzone" style="padding-left:0px;padding-right:0px">{{item.city}}</span>
						<span class="addrzone" style="padding-left:0px;padding-right:0px">{{item.dist}}</span> &nbsp; {{item.address}}
					</div>
				</div>
				<div class="addremail" style="line-height:normal; padding-top: 15px;">

					<div style="display: inline-block; float: left; transform: translateY(-1px);" @click="SetDefault(item.id)">

						<svg v-show="item.isdefault==1" class="icon" style="width: 18px; height: 18px;vertical-align: middle;fill: #0193e6;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1953">
							<path d="M755.2 275.2L416 614.4 268.8 448c-25.6-25.6-64-25.6-89.6 0-25.6 25.6-25.6 64 0 89.6l185.6 211.2c12.8 12.8 32 19.2 44.8 19.2 19.2 0 32-6.4 44.8-19.2l384-384c25.6-25.6 25.6-64 0-89.6-19.2-25.6-64-25.6-83.2 0z" fill="#0193e6" p-id="1954"></path>
							<path d="M896 0H128C57.6 0 0 57.6 0 128v768c0 70.4 57.6 128 128 128h768c70.4 0 128-57.6 128-128V128c0-70.4-57.6-128-128-128z m64 896c0 38.4-25.6 64-64 64H128c-38.4 0-64-25.6-64-64V128c0-38.4 25.6-64 64-64h768c38.4 0 64 25.6 64 64v768z" fill="#0193e6" p-id="1955"></path>
						</svg>
						<svg v-show="item.isdefault==0" class="icon" style="width: 18px; height: 18px;vertical-align: middle;fill: #0193e6;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1953">
							<path d="M896 0H128C57.6 0 0 57.6 0 128v768c0 70.4 57.6 128 128 128h768c70.4 0 128-57.6 128-128V128c0-70.4-57.6-128-128-128z m64 896c0 38.4-25.6 64-64 64H128c-38.4 0-64-25.6-64-64V128c0-38.4 25.6-64 64-64h768c38.4 0 64 25.6 64 64v768z" fill="#0193e6" p-id="1955"></path>
						</svg>

					</div>

					<div style="margin-left: 3px; display: inline;" @click="SetDefault(item.id)">默认地址</div>

					<div style="display: inline-block; float: right;" @click="DeleteAddress(item.id)">
						<svg class="icon" style="width: 18px; height: 18px;vertical-align: middle;fill: #ccc;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2462">
							<path d="M920.771 271.295c0 10.452-8.129 18.58-18.58 18.58l-55.742 0 0 550.448c0 63.871-41.807 118.45-92.902 118.45L270.454 958.773c-51.097 0-92.903-52.258-92.903-116.128L177.551 289.875 121.81 289.875c-10.452 0-18.581-8.128-18.581-18.58l0-37.161c0-10.452 8.128-18.581 18.581-18.581l179.418 0 40.645-96.967c11.612-28.451 46.451-51.677 77.225-51.677l185.805 0c30.773 0 65.613 23.226 77.225 51.677l40.646 96.967 179.418 0c10.451 0 18.58 8.129 18.58 18.581L920.772 271.295zM772.127 289.875 251.873 289.875l0 550.448c0 27.87 15.678 44.128 18.581 44.128l483.093 0c2.902 0 18.58-16.258 18.58-44.128L772.127 289.875zM400.517 754.388c0 10.452-8.129 18.58-18.581 18.58l-37.161 0c-10.452 0-18.58-8.128-18.58-18.58L326.195 419.938c0-10.452 8.128-18.58 18.58-18.58l37.161 0c10.452 0 18.581 8.128 18.581 18.58L400.517 754.388zM642.063 215.553l-27.869-67.935c-1.742-2.323-6.969-5.807-9.869-6.387L420.259 141.231c-3.484 0.581-8.128 4.064-9.871 6.387l-28.451 67.935L642.063 215.553zM549.161 754.388c0 10.452-8.128 18.58-18.58 18.58l-37.162 0c-10.452 0-18.58-8.128-18.58-18.58L474.839 419.938c0-10.452 8.128-18.58 18.58-18.58l37.162 0c10.452 0 18.58 8.128 18.58 18.58L549.161 754.388zM697.805 754.388c0 10.452-8.129 18.58-18.58 18.58l-37.162 0c-10.449 0-18.578-8.128-18.578-18.58L623.485 419.938c0-10.452 8.129-18.58 18.578-18.58l37.162 0c10.451 0 18.58 8.128 18.58 18.58L697.805 754.388z" p-id="2463"></path>
						</svg>
					</div>

				</div>
			</div>

			<div class="flexcenter" style="border: 1px solid #fff; background: #0193e6; color: #fff; margin: 50px auto; width: 86%; height: 40px; border-radius: 4px; padding:0 15px;" @click="AddNew">添加新地址</div>
			<div style="height: 30px;"></div>
		</div>
		<div class="inpage" id="newAddress" style="background: #fff; display: none;">
			<div class="textbox">

				<input type="text" id="txt_name" class="text" v-model="name" placeholder="收件人姓名">
				<span class="require">*必填</span>
			</div>
			<div class="textbox">
				<input type="text" id="txt_phone" class="text" v-model="mobile" placeholder="手机号码">
				<span class="require">*必填</span>
			</div>
			
			<div class="textbox" style="position: relative;">
				<input type="text" id="txt_email" class="text" :value="province" placeholder="请选择省份">
				<div style="height: 100%; width: 100%; position: absolute; top: 0;">
					<select class="text" v-model="province" style="opacity: 0;">

						<option v-for="item in Address.pro" :value="item.name">{{item.name}}</option>
					</select>
					<span class="require">*必填</span>
				</div>
			</div>
			<div class="textbox" style="position: relative;">
				<input type="text" id="txt_email" class="text" :value="city" placeholder="请选择城市">
				<div style="height: 100%; width: 100%; position: absolute; top: 0;">
					<select class="text" v-model="city" style="opacity: 0;">
						<option value="请选择城市">请选择城市</option>
						<option v-for="item in Address.city" :value="item.name">{{item.name}}</option>
					</select>
					<span class="require">*必填</span>
				</div>

			</div>
			<div class="textbox" style="position: relative;">
				<input type="text" id="txt_email" class="text" :value="district_area" placeholder="请选择城市">
				<div style="height: 100%; width: 100%; position: absolute; top: 0;">
					<select class="text" v-model="district_area" style="opacity: 0;">
						<option value="请选择区域">请选择区域</option>
						<option v-for="item in Address.dist" :value="item">{{item}}</option>
					</select>
					<span class="require">*必填</span>
				</div>

			</div>
			<div class="textbox" style="height: 100px;">
				<textarea id="txt_detail" class="text" v-model="address" placeholder="详细地址" style="margin-top: 10px; height: 80px; width: 80%;"></textarea>
				<span class="require" style="display: inline-block; float: right; margin-right: 0;">*必填&nbsp;</span>
			</div>
			<div class="blank10"></div>
			<div style=" height: 100px; padding-top: 30px; background: #fff;">
				<div class="btnBlack" style="background: #0193e6; color: #fff; width: 45%; float: right; margin-right: 3%;" @click="SaveAddress">保&nbsp;&nbsp;&nbsp;存</div>
				<div class="btnBlack" style="background: #0193e6; color: #fff; width: 45%; float: left;margin-left: 3%;" @click="HideNewAddress">取&nbsp;&nbsp;&nbsp;消</div>

			</div>

			<div style="height: 30px;"></div>
		</div>
	</div>

</template>

<script>
	export default {

		data() {
			return {
				province: "",
				city: "",
				district_area: "",
				district: "",
				name: "",
				mobile: "",
				email: "",
				address: "",
				default: "",
				Address: {
					pro: [],
					city: [],
					dist: []
				},
				MyList: []
			}
		},
		computed: {

		},
		watch: {
			province: function(n, o) {
				for(let item of this.Address.pro) {

					if(item.name == n) {
						this.Address.city = item.city;
						this.city = "请选择城市"
						this.district_area = "请选择区域";
						console.log(this.Address.city)
						break
					}
				}
			},
			city: function(n, o) {
				for(let item of this.Address.city) {

					if(item.name == n) {
						this.Address.dist = item.area;
						this.district_area = "请选择区域";

						break;
					}
				}
			},
			district_area: function(n, o) {
				console.log(n)
			}
		},
		beforeMount() {
			//http://mediabook.oss-cn-shanghai.aliyuncs.com/xxcrm/citysdata.js
			if(!window.AreaList) {
				window.loadScript("/static/city.data.min.js", () => {
					this.Address.pro = window.MSCitys;
				})
			} else {
				this.Address.pro = window.MSCitys;
			}

			this.GetList();
		},
		components: {

		},

		mounted() {
			document.title = "我的地址";
			window.PageOK();

		},
		methods: {

			GetList() {
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=address"
				}).then(result => {
					this.MyList = result.data.data.rows
					
				})
			},
			SaveAddress() {

				console.log("district_area" + this.district_area)
				if(this.MyList.length == 0) {
					this.is_default = 1;
				}
				if(!(/^1(3|4|5|6|7|8)\d{9}$/.test(this.mobile))) {
					$alert("手机号码格式不正确");
					return false;
				}
				
				if(this.name == "" || this.address == "" ||
					this.province == "请选择省份" || this.city == "请选择城市" || this.dist == "请选择区域") {
					$alert("请填写完整信息");
					return
				}
				
				
				this.$store.dispatch("POST", {
					url: "/api/wx/?action=saveaddr",
					query: {
						username: this.name,
						phone: this.mobile,
						province: this.province,
						city: this.city,
						dist: this.district_area,
						address: this.address,
						default: this.default
					}
				}).then(result => {
					
						this.HideNewAddress();

						this.GetList();
					
				}, err => {
					$DataCheck(err);
					$alert(err.response.data.message)
				})
			},
			HideNewAddress() {
				$("#newAddress").animate({
					translate3d: "0,-50%,0",
					opacity: 0
				}, 300, "easein", function() {
					$("#newAddress").hide()
				})
			},
			AddNew() {
				$("#newAddress").css("opacity", 0).show().animate({
					translate3d: "0,-50%,0"
				}, 0, function() {
					$("#newAddress").animate({
						translate3d: "0,0,0",
						opacity: 1
					}, 300, "easein")
				})
			},
			ChooseItem(id) {
				sessionStorage.setItem("address", id);
				history.back();

			},
			SetDefault(id) {
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=setdefault&id=" + id
					
				}).then(result => {
					this.GetList();
				})
			},
			DeleteAddress(id) {
				$confirm({
					msg: "确定删除？",
					okcb: () => {
						this.$store.dispatch("GET", {
							url: "/api/wx/?action=deladdr&id=" + id

						}).then(result => {
							this.GetList();
						})
					}
				})
			},
		}
	}
</script>
<style @scope>
	
.addr {
	width: 86%;
	padding: 10px 15px 10px 15px;
	margin: 0 auto;
	background-color: #FFFFFF;
	border-radius: 6px;
	margin-top: 20px;
}

.addrtitle {
	width: 100%;
	font-weight: bolder;
}

.addruser {}

.addrphone {
	width: 80px;
	overflow: hidden;
	display: inline-block;
	float: right;
	overflow: hidden;
	text-align: right;
}

.addremail {
	width: 100%;
	height: 30px;
	line-height: 30px;
	overflow: hidden;
}

.addrzone {
	padding-left: 8px;
	padding-right: 8px;
}

.btndelete {
	width: 5rem;
	overflow: hidden;
	display: inline-block;
	float: right;
	overflow: hidden;
	text-align: right;
}

.btnAdd {
	height: 40px;
	width: 40px;
	border: 2px solid #999999;
	border-radius: 22px;
	float: right;
	margin-right: 8%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
}

.btnAdd:active {
	background-color: #000000;
}

.textbox {
	width: 100%;
	height: 40px;
	background-color: #fff;
	margin: 0 auto;
	margin-top: 2px;
	overflow: hidden;
	padding: 3px 0;
	border-bottom: 2px solid #eee;
}

.text {
	padding: 0;
	margin: 0;
	outline: none;
	border: 0;
	width: 85%;
	height: 40px;
	padding-left: 8px;
}

.require {
	width: 10%;
	color: red;
	text-align: center;
	float: right;
	font-size: 12px;
	display: inline-block;
	margin-top: 14px;
}

.btnBlack {
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #b0211f;
	color: #FFFFFF;
	margin: 0 auto;
}

.btnBlack:active {
	background-color: #333333;
}

.location {
	height: 100%;
	width: 70%;
	background-color: #FFFFFF;
	position: absolute;
	z-index: 2;
}

.addr2 {
	width: 28%;
	height: 100%;
	background-color: #FFFFFF;
	border: 0;
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: none;
	margin: 0;
	padding-left: 0px;
	color: inherit;
	outline: none;
	transform: translateY(-0.2rem);
}

</style>